







































import {
  Vue, Prop, Component,
} from "vue-property-decorator";
import AusterButton from "./AusterButton.vue";
import AusterInput from "./AusterInput.vue";

@Component({
  components: {
    AusterButton,
    AusterInput,
  },
})
export default class CookiesDefinitionsModal extends Vue {
  @Prop() openModal?: boolean;

  showModal = false;

  showCookiesDefinitions() {
    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
  }

  saveCookiesSettings() {
    localStorage.cookiePolicyHidden = true;
    this.$emit("saveCookiesSettings");
    this.showModal = false;
  }

}
