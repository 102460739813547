import { render, staticRenderFns } from "./AusterInput.vue?vue&type=template&id=326c050a&scoped=true&"
import script from "./AusterInput.vue?vue&type=script&lang=ts&"
export * from "./AusterInput.vue?vue&type=script&lang=ts&"
import style0 from "./AusterInput.vue?vue&type=style&index=0&id=326c050a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "326c050a",
  null
  
)

export default component.exports