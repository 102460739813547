

















import { Vue, Prop, Component } from "vue-property-decorator";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

@Component({
  components: {
    ClipLoader
  }
})
export default class AusterButton extends Vue {
  @Prop() click?: any;

  @Prop() background?: string;

  @Prop() color?: string;

  @Prop() borderColor?: string;

  @Prop() kind?: string;

  @Prop({ default: false }) loadingBtn?: boolean;

  @Prop({ default: false }) isDisabled?: boolean;

  get classObject() {
    if (this.kind) {
      return { [this.kind]: true };
    }
    return {};
  }

  onClick() {
    this.$emit("click");
    if (this.click) {
      this.click();
    }
  }
}
