




















































































































































































import { Component, Vue } from "vue-property-decorator";
import AusterButton from "../components/AusterButton.vue";
import Navbar from "../components/Navbar.vue";
import FooterPage from "../components/FooterPage.vue";
import CookiesDefinitionsModal from "../components/CookiesDefinitionsModal.vue";

@Component({
  components: {
    AusterButton,
    Navbar,
    FooterPage,
    CookiesDefinitionsModal,
  },
})
export default class Home extends Vue {
  background?: string = "";

  showMenu = false;

  email = undefined;

  loading = false;

  code = "";

  showCookieConsent = !localStorage.cookiePolicyHidden; // Initialize based on localStorage

  // The hideCookies function in the parent component
  hideCookies() {
    this.showCookieConsent = false;
  }

  created() {
    // Check localStorage when the component is created
    this.showCookieConsent = !localStorage.cookiePolicyHidden;

    // You can also add a listener to update if localStorage changes during the session
    window.addEventListener("storage", this.handleStorageChange);
  }

  beforeDestroy() {
    // Remove the storage listener when the component is destroyed
    window.removeEventListener("storage", this.handleStorageChange);
  }

  // Handle changes to localStorage
  handleStorageChange(event: StorageEvent) {
    if (event.key === "cookiePolicyHidden") {
      this.showCookieConsent = !event.newValue;
    }
  }

  acceptCookies() {
    localStorage.cookiePolicyHidden = true;
    this.showCookieConsent = false;
  }
}
