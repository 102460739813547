import isEmail from 'validator/es/lib/isEmail';
import isURL from 'validator/es/lib/isURL';
import isDate from 'validator/es/lib/isDate';
import isUUID from 'validator/es/lib/isUUID';

import { EntityDescription } from './_crud';

export function validateEntity(entity: any, entityDescription: EntityDescription, requiredKeys?: string[]): { [fieldKey: string]: string | undefined } {
  const errors: { [fieldKey: string]: string | undefined } = {};

  if (!entityDescription) {
    return {};
  }

  // validate fields
  entityDescription.forEach((field) => {
    // check if validation rule is set
    if (!field.validation) {
      return;
    }

    const entityValue = entity[field.key];

    // handle unset entity value
    if (!entityValue) {
      // set error message if key is required
      if (requiredKeys?.includes(field.key) || field.validation.required === true) {
        errors[field.key] = 'Campo obrigatório';
      }
      // skip other validations
      return;
    }

    // check if it is alphanumeric
    const regex = /"^(?=.*[A-Za-z])(?=.*\d).{8,}$"/;

    // check field length
    if (field.validation.lengthMin && entityValue?.length < field.validation.lengthMin && !regex.exec(entity.password)) {
      errors[field.key] = `Deve ter pelo menos ${field.validation.lengthMin} caracteres (letras e números)`;
    }

    if (field.validation.lengthMax && entityValue?.length > field.validation.lengthMax) {
      errors[field.key] = `Deve ter menos que ${field.validation.lengthMax} caracteres`;
    }

    // check numeric range
    if (field.validation.min && parseFloat(entityValue) < field.validation.min) {
      errors[field.key] = `Deve ser superior a ${field.validation.min}`;
    }

    if (field.validation.max && parseFloat(entityValue) > field.validation.max) {
      errors[field.key] = `Deve ser inferior a ${field.validation.max}`;
    }

    // check specific validation
    switch (field.validation.kind) {
      case 'cpf':
        errors[field.key] = !validateCPF(entityValue) ? 'CPF inválido' : undefined;
        break;
      case 'cnpj':
        errors[field.key] = !validateCNPJ(entityValue) ? 'CNPJ inválido' : undefined;
        break;
      case 'cpfOrCnpj':
        errors[field.key] = !(validateCNPJ(entityValue) || validateCPF(entityValue)) ? 'CPF ou CNPJ inválido' : undefined;
        break;
      case 'email':
        errors[field.key] = !isEmail(entityValue) ? 'E-mail inválido' : undefined;
        break;
      case 'phone':
        errors[field.key] = !isBrPhone(entityValue) ? 'Telefone inválido' : undefined;
        break;
      case 'url':
        errors[field.key] = !isURL(entityValue) ? 'URL inválida' : undefined;
        break;
      case 'match':
        if (field.validation.matchKey !== undefined) {
          errors[field.key] = entityValue !== entity[field.validation.matchKey] ? 'Dados não conferem' : undefined;
        }
        break;
      case 'brDate':
        errors[field.key] = !isDate(entityValue, 'DD/MM/YYYY') ? 'Data inválida' : undefined;
        break;
      case 'uuid':
        errors[field.key] = !isUUID(entityValue) ? 'Identificador inválido' : undefined;
        break;
    }
  });

  return errors;
}

function isBrPhone(phone: string) {
  return typeof phone === 'string' && /^\((\d{2})\)\s(\d{4,5}-\d{4})$/.test(phone);
}

function validateCPF(cpf: string) {
	cpf = cpf.replace(/[^\d]+/g, '');
	if (cpf === '') { return false; }
  // handle known invalid CPFs
	if (cpf.length !== 11 || cpf === '00000000000' || cpf === '11111111111' || cpf === '22222222222' || cpf === '33333333333' || cpf === '44444444444' || cpf === '55555555555' || cpf === '66666666666' || cpf === '77777777777' || cpf === '88888888888' || cpf === '99999999999') {
		return false;
	}
	// validate first digit
	let add = 0;
	for (let i = 0; i < 9; i ++) {
		add += parseInt(cpf.charAt(i)) * (10 - i);
	}
	let rev = 11 - (add % 11);
	if (rev === 10 || rev === 11) {
		rev = 0;
	}
	if (rev !== parseInt(cpf.charAt(9))) {
		return false;
	}
	// validate second digit
	add = 0;
	for (let i = 0; i < 10; i ++) {
		add += parseInt(cpf.charAt(i)) * (11 - i);
	}
	rev = 11 - (add % 11);
	if (rev === 10 || rev === 11) {
		rev = 0;
	}
	if (rev !== parseInt(cpf.charAt(10))) {
		return false;
	}
	return true;
}


function validateCNPJ(cnpj: string) {
	// adapted from https://www.geradorcnpj.com/javascript-validar-cnpj.htm

	cnpj = cnpj.replace(/[^\d]+/g,'');

	if (cnpj === '') return false;

	if (cnpj.length !== 14)
		return false;

	// handle known invalid CNPJs
	const invalidCNPJList = ['00000000000000', '11111111111111', '22222222222222', '33333333333333', '44444444444444', '55555555555555', '66666666666666', '77777777777777', '88888888888888', '99999999999999'];
	if (invalidCNPJList.includes(cnpj)) {
		return false;
	}

	// validate digits
	let tamanho = cnpj.length - 2
	let  numeros = cnpj.substring(0, tamanho);
	const digitos = cnpj.substring(tamanho);
	let soma = 0;
	let pos = tamanho - 7;
	for (let i = tamanho; i >= 1; i--) {
		soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
		if (pos < 2)
			pos = 9;
	}
	let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
	if (resultado !== parseInt(digitos.charAt(0)))
		return false;

	tamanho = tamanho + 1;
	numeros = cnpj.substring(0,tamanho);
	soma = 0;
	pos = tamanho - 7;
	for (let i = tamanho; i >= 1; i--) {
		soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
		if (pos < 2)
			pos = 9;
	}
	resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
	if (resultado !== parseInt(digitos.charAt(1)))
		return false;

	return true;
}
