import { CrudModel } from './_crud';

export class AuthModel extends CrudModel {
  constructor() {
    super('auth');
  }

  async getUserDescription() {
    return this.post(null, 'getUserEntityDescription');
  }

  async signup(userInfo: any) {
    return this.post(null, 'signup', userInfo);
  }

  async requestPasswordReset(email: string) {
    return this.post(null, 'requestPasswordReset', { email });
  }

  async resetPassword(passwordResetCode: string, password: string) {
    return this.post(null, 'resetPassword', { passwordResetCode, password });
  }

  async requestLoginCode(email: string) {
    return this.post(null, 'requestLoginCode', { email });
  }
}

export default new AuthModel();
