import { render, staticRenderFns } from "./CookiesDefinitionsModal.vue?vue&type=template&id=160da536&scoped=true&"
import script from "./CookiesDefinitionsModal.vue?vue&type=script&lang=ts&"
export * from "./CookiesDefinitionsModal.vue?vue&type=script&lang=ts&"
import style0 from "./CookiesDefinitionsModal.vue?vue&type=style&index=0&id=160da536&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "160da536",
  null
  
)

export default component.exports