import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import authModule from "./auth.module";

export default new Vuex.Store({
    state: {
      activeUser: JSON.parse(localStorage.getItem("activeUser")) || {},
    },
    mutations: {
      UPDATE_ACTIVE_USER(state, user) {
        state.activeUser = { ...user };
        localStorage.setItem("activeUser", JSON.stringify(state.activeUser)); // persist
      }
    },
    actions: {
      updateActiveUser({ commit }, user) {
        commit('UPDATE_ACTIVE_USER', user);
      }
    },
    modules: {
      auth: authModule,
    },
    strict: false,
});
