














import { Vue, Prop, Component } from "vue-property-decorator";

@Component({})
export default class AusterInput extends Vue {
  @Prop({ default: "text" })
  type: any;

  @Prop()
  mask: any;

  @Prop()
  iconFunction: Function;

  @Prop({ default: "" })
  iconName: string;

  @Prop()
  placeholder: string;

  @Prop({ default: "" })
  value: any;

  @Prop({ default: "" })
  error: string;

  fieldId: string;

  created() {
    this.fieldId = `input-${Math.round(Math.random() * 1000000)}`;
  }
}
