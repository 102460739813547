import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';

import store from "@/store/store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
   // =============================================================================
    // START-PAGE
    // =============================================================================
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/cadastro',
    name: 'Cadastro',
    component: () => import( '../views/pages/Signup.vue'),
  },
  {
    path: '/recuperar-senha',
    name: 'Recuperarsenha',
    component: () => import('../views/pages/PasswordRecovery.vue'),
  },
  {
    path: '/canal-de-privacidade',
    name: 'Canal de privacidade',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/PrivacyChannel.vue'),
  },
  {
    path: '/encontrar-psicologo',
    name: 'Encontrar psicologo',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/SearchPsychologist.vue'),
  },
  {
    path: '/faq',
    name: 'Faq',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/Faq.vue'),
  },
  {
    path: '/contato',
    name: 'Contato',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/Contact.vue'),
  },
  // =============================================================================
  // LOGGED IN ROUTES
  // =============================================================================
  {
    path: '/dash',
      component: () => import('../views/pages/logged-in-page/Main.vue'),
      redirect: '/dash/agenda',
      meta: { authRequired: true },
      children: [
  // =============================================================================
  // LOGGED IN PAGES
  // =============================================================================
        {
          path: '/dash/minha-conta',
          name: 'minhaconta',
          meta: { authRequired: true },
          component: () => import('../views/pages/logged-in-page/MyAccount.vue'),
        },
        {
          path: '/dash/busca-psicologo',
          name: 'buscapsicologo',
          meta: { authRequired: true },
          component: () => import('../views/pages/logged-in-page/Search.vue'),
        },
        {
          path: '/dash/agenda',
          name: 'agenda',
          meta: { authRequired: true },
          component: () => import('../views/pages/logged-in-page/Schedule.vue'),
        },
        {
          path: '/dash/minhas-consultas',
          name: 'minhasconsultas',
          meta: { authRequired: true },
          component: () => import('../views/pages/logged-in-page/Appointments.vue'),
        },
        {
          path: '/dash/relatorio-consultas',
          name: 'relatorioconsultas',
          meta: { authRequired: true },
          component: () => import('../views/pages/logged-in-page/AppointmentsReport.vue'),
        },
        {
          path: '/dash/psicologo/:id',
          name: 'psicologo',
          meta: { authRequired: true },
          component: () => import('../views/pages/logged-in-page/PsychologistPage.vue'),
        },
        {
          path: '/dash/consulta/:id',
          name: 'Detalhes da consulta',
          meta: { authRequired: true },
          component: () => import('../views/pages/logged-in-page/AppointmentDetails.vue'),
        },
        {
          path: '/dash/sala/:id',
          meta: { authRequired: true },
          component: () => import('../views/pages/logged-in-page/VideoRoom.vue'),
        },
        {
          path: '/dash/solicitacoes',
          name: 'solicitacoes',
          meta: { authRequired: true },
          component: () => import('../views/pages/logged-in-page/PendingAppointments.vue'),
        },
        {
          path: '/dash/perfil',
          name: 'perfil',
          meta: { authRequired: true },
          component: () => import('../views/pages/logged-in-page/MyProfile.vue'),
        },
        {
          path: '/dash/logout',
          name: 'logout',
          meta: { authRequired: true },
          component: () => import('../views/pages/logged-in-page/Logout.vue'),
        }
//         {
//           path: '/minhaconta',
//           name: 'Publicações',
//           meta: { authRequired: true },
//           // component: () => import('./views/pages/logged-in-page/Posts.vue'),
//         },
//         {
//           path: '/agenda',
//           name: 'Publicações',
//           meta: { authRequired: true },
//           // component: () => import('./views/pages/logged-in-page/Posts.vue'),
//         },
      ],
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.authRequired && !(store.state as any).auth.isUserLoggedIn()) {
    router.push({ path: "/", query: { to: to.path } });
  }
  return next();
});

export default router;
