import { Module } from "vuex";

import axios from "../axios";
import router from "../router";

const authModule: Module<any, any> = {
  namespaced: true,
  state: {
    isUserLoggedIn() {
      return !!localStorage.getItem("accessToken") && !!localStorage.getItem("activeUser");
    },
    accessToken() {
      return localStorage.getItem("accessToken");
    },
  },
  mutations: {
    SET_TOKEN(state, token: string) {
      localStorage.setItem("accessToken", token);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
  },
  actions: {
    async login({ commit }, payload) {
      try {
        //console.log(payload);
        const loginResponse = await axios.post("auth/login", payload);
        const session = loginResponse.data;
        commit("SET_TOKEN", session.token);
        commit("UPDATE_ACTIVE_USER", session.user, { root: true });
        router.push((router.currentRoute.query.to || "/dash") as string);
        return session;
      } catch (error) {
        console.error(error);
        if (error.response?.status === 400 || error.response?.status === 404) {
          throw Error("Email, senha ou código de confirmação incorretos");
        }
        throw Error(error.response?.data?.message || "Houve um erro na conexão com o servidor");
      }
    },
    async setSession({ commit }, session) {
      commit("SET_TOKEN", session.token);
      commit("UPDATE_ACTIVE_USER", session.user, { root: true });
      router.push((router.currentRoute.query.to || "/dash") as string);
    },
    async logout({ commit }) {
      commit("SET_TOKEN", "");
      commit("UPDATE_ACTIVE_USER", {}, { root: true });
      router.push("/");
    },
    async loadTokenFromStorage({ commit, state }) {
      if (state.isUserLoggedIn()) {
        const token = state.accessToken();
        commit("SET_TOKEN", token);
      }
    },
  },
}

export default authModule;
