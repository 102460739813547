













































import {
  Vue, Prop, Component, Watch,
} from "vue-property-decorator";
import AusterButton from "./AusterButton.vue";
import AusterInput from "./AusterInput.vue";

import authModel from "@/models/auth.model";
import { validateEntity } from '../models/entity-validation';

@Component({
  components: {
    AusterButton,
    AusterInput,
  },
})
export default class LoginModal extends Vue {
  @Prop() openModal?: boolean;

  showModal = false;
  loadingButton = false;
  disableButton = false;
  checkLogin = false;

  userInfo: any = {
    email: "",
    password: "",
    loginConfirmationCode: "",
  };

  fieldErrors: any = {};

  showLogin() {
    if (this.$store.state.auth.isUserLoggedIn()) {
      this.$router.push("/dash");
    } else {
      this.showModal = true;
    }
  }

  closeModal() {
    this.showModal = false;
    this.checkLogin = false;
  }


  async login() {
    this.validateFields();
    if (Object.values(this.fieldErrors).filter(error => !!error).length) {
      console.error(this.fieldErrors);
      return;
    }

    this.checkLogin = true;
    if (this.userInfo.loginConfirmationCode !== "") {
      try {
        this.loadingButton = true;
        this.disableButton = true;
        await this.$store.dispatch("auth/login", { email: this.userInfo.email, password: this.userInfo.password, loginConfirmationCode: this.userInfo.loginConfirmationCode });
      } catch (error) {
        if (error.message === "Account is pending approval") {
          error.message = "Sua conta ainda não foi aprovada";
        }
        this.$vs.notification({
          title: 'Erro',
          text: error.message,
          // iconPack: 'feather',
          // icon: 'icon-alert-circle',
          color: 'danger'
        });
        console.error(error);
        this.loadingButton = false;
        this.disableButton = false;
      }
    } else {
      try {
        await authModel.requestLoginCode(this.userInfo.email);
        this.$vs.notification({ color: "success", title: "Sucesso", text: "O código foi enviado para o seu e-mail." });
      } catch (error) {
        if (error.statusCode === 404 || error.message === 'Not Found' || error.message === 'API_NOT_FOUND') {
          error.message = "E-mail não cadastrado."
        }
        this.$vs.notification({
          title: 'Erro',
          text: error.message,
          color: 'danger'
        });
        console.error(error);
      }
    }
  }

  validateFields() {
    const validationDefinition: any = [
      { key: "email", validation: { kind: 'email' } },
      { key: "password", validation: { lengthMin: 8, lengthMax: 100 } },
    ];

    this.fieldErrors = validateEntity(this.userInfo, validationDefinition, ["email", "password"]);
  }

  @Watch('userInfo', { deep: true })
  userInfoChanged() {
    // if a previous error was found, update validation when data changes
    if (Object.keys(this.fieldErrors).length) {
      this.validateFields();
    }
  }
}
