import Vue from 'vue';
import * as VueScrollTo from 'vue-scrollto';
import Vuesax from 'vuesax';
import App from './App.vue';
import router from './router';
// Globally Registered Components
import './globalComponents';
// Styles: SCSS
import './scss/main.scss';
// Vuesax Component Framework

import 'material-icons/iconfont/material-icons.css';

import 'vuesax/dist/vuesax.css'; // Vuesax

// Global Vue filters
import './filters';

// Vuex
import store from  './store/store';

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

/*
Sentry.init({
  Vue,
  dsn: "https://e75e8e65b3334e13bf7b56683fb4bef8@o527393.ingest.sentry.io/5722100",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});
*/

//Vue.use(Vuesax);
Vue.use(Vuesax, {
    colors:{
      primary:'#2E7179',
      success:'rgb(23, 201, 100)',
      danger:'rgb(242, 19, 93)',
      warning:'rgb(255, 130, 0)',
      dark:'rgb(36, 33, 69)'
    }  
});

Vue.config.productionTip = false;

Vue.use(VueScrollTo as any);

// Vue Select
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
Vue.component('v-select', vSelect);

// Vue The Mask
import VueTheMask from 'vue-the-mask';
Vue.use(VueTheMask);

export default new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
