




















































import { Vue, Component } from "vue-property-decorator";
import LoginModal from "./LoginModal.vue";

@Component({
  components: {
    LoginModal,
  },
})
export default class Navbar extends Vue {
  showMenu = false;

  loginModal = false;

  toggled = false;


  loading = false;

  navLinks = [
    {
      link: "Home",
      to: "/"
    },
    {
      link: "Encontrar profissional",
      to: "/encontrar-psicologo"
    },
    {
      link: "Perguntas e respostas",
      to: "/faq"
    },
    {
      link: "Canal de Privacidade",
      to: "/canal-de-privacidade"
    },
    {
      link: "Contatos",
      to: "/contato"
    },
  ]

  mounted() {
    if (this.$route.query.to && this.$refs.loginModal) {
      (this.$refs.loginModal as any).showLogin();
    }
  }

  async openMenu() {
    this.showMenu = !this.showMenu;
    this.toggled = !this.toggled;
  }
}
