import axios from "../axios";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import Vue from "@/main";

import store from "@/store/store";

const requestErrorHandler = async (error: any) => {
	console.error(error);
	if (!error.response) {
		Vue.$vs.notification({
			title: "Erro de conexão com servidor",
			text: "Verifique sua conexão com a internet e tente novamente.",
			iconPack: "feather",
			icon: "icon-alert-circle",
			color: "danger",
		});
		throw new Error("ERR_CONNECTION");
	}

	console.log("error handled");

	switch (error.response.status) {
		case 401:
			store.dispatch("auth/logout");
			return;
		case 403:
			throw Error("API_FORBIDDEN"); // user does not have correct privileges
		case 404:
			throw Error("API_NOT_FOUND");
		case 429:
			throw Error("API_RATE_LIMIT");
		case 500:
			throw Error("API_SERVER_ERROR");
		default:
			throw error.response.data;
	}
};

function deflateEntity(entity: any, prefix = "") {
	let flatEntity = Object.assign({}, entity);
	Object.keys(entity).forEach(key => {
		if (flatEntity[key] instanceof Array) {
			flatEntity[key] = flatEntity[key].map((subEntity: any) => deflateEntity(subEntity));
		}
		if (flatEntity[key] instanceof Object && !(flatEntity[key] instanceof Array)) {
			flatEntity = { ...flatEntity, ...deflateEntity(flatEntity[key], prefix ? `${prefix}.${key}` : key) };
			delete flatEntity[key];
		} else if (prefix) {
			flatEntity[`${prefix}.${key}`] = flatEntity[key];
			delete flatEntity[key];
		}
	});
	return flatEntity;
}

function inflateEntity(flatEntity: any) {
	const entity = {};
	Object.keys(flatEntity).forEach((key) => {
		let entityLevel: any = entity;
		const levelsKeys = key.split(".");
		levelsKeys.forEach((levelKey, level) => {
			if (level === levelsKeys.length - 1) { // if last level, set value
				entityLevel[levelKey] = flatEntity[key] instanceof Array ? flatEntity[key].map((subEntity: any) => inflateEntity(subEntity)) : flatEntity[key];
			} else if (!entityLevel[levelKey]) { // otherwise, initialize if necessary
				entityLevel[levelKey] = {};
			}
			entityLevel = entityLevel[levelKey]; // update current level
		});
	});
	return entity;
}

export async function post(controller: string, command: string, data: any, sendToken = false) {
	try {
		const response = await axios.post(`${controller}/${command}`, data);
		if (response.data) {
			return response.data;
		}
		throw Error("API_DATA_NOT_FOUND");
	} catch (error) {
		return requestErrorHandler(error);
	}
}

/*
export async function crudDescription(controller: string) {
	try {
		const response = await axios.get(`${controller}/getEntityDescription`);
		return response.data;
	} catch (error) {
		return requestErrorHandler(error);
	}
}
*/

//foi deixado igual o admin, ele não estava pegando os joins
export async function crudDescription(controller: string) {
	try {
    const response = await axios.get(`${controller}/getEntityDescription`);
    // add necessary field joins for related properties
    const { data: entityDescription } = response;
    entityDescription.forEach((fieldDescription: any) => {
      const joinMatches = fieldDescription.key.match(/(.+)\..+/);
      if (joinMatches && joinMatches.length > 1) {
        fieldDescription.join = joinMatches[1];
      }
    });
    return entityDescription;
	} catch (error) {
		return requestErrorHandler(error);
	}
}

export async function crudCreate(controller: string, data: any) {
	try {
		const response = await axios.post(controller, inflateEntity(data));
		if (response.data) {
			return response.data;
		}
		throw Error("API_DATA_NOT_FOUND");
	} catch (error) {
		return requestErrorHandler(error);
	}
}

export async function crudQuery(controller: string, queryParameters: any) {
	//console.log('logD search parameteres', queryParameters);
	const queryString = RequestQueryBuilder.create(queryParameters).query();
	try {
		const response = await axios.get(`${controller}?${queryString}`);
		if (response.data && Array.isArray(response.data)) {
			response.data = response.data.map((entity: any) => deflateEntity(entity));
		} else if (response.data && response.data.data && Array.isArray(response.data.data)) {
			response.data.data = response.data.data.map((entity: any) => deflateEntity(entity));
		}
		return response.data;
	} catch (error) {
		return requestErrorHandler(error);
	}
}

export async function crudRead(controller: string, id: string | number, queryParameters: any) {
	const queryString = RequestQueryBuilder.create(queryParameters).query();
	try {
		const response = await axios.get(`${controller}/${id}?${queryString}`);
		return response.data ? deflateEntity(response.data) : null;
	} catch (error) {
		return requestErrorHandler(error);
	}
}

export async function crudUpdate(controller: string, id: string | number, data: any) {
	try {
		const response = await axios.patch(`${controller}/${id}`, inflateEntity(data));
		if (response.data) {
			return response.data;
		}
		throw Error("API_DATA_NOT_FOUND");
	} catch (error) {
		return requestErrorHandler(error);
	}
}

export async function crudDelete(controller: string, id: string | number, auditNotes: string) {
	try {
		await axios.request({
			url: `${controller}/${id}`,
			method: "delete",
			data: { auditNotes },
		});
		return true;
	} catch (error) {
		return requestErrorHandler(error);
	}
}

export async function crudPost(controller: string, path: string, data?: any) {
	try {
		//console.log('logD crud:', `${controller}/${path}`, JSON.stringify(data));
		const response = await axios.post(`${controller}/${path}`, data);
		return response.data;
	} catch (error) {
		return requestErrorHandler(error);
	}
}

export async function crudGet(controller: string, path: string, params?: any) {
	try {
		const response = await axios.get(`${controller}/${path}`, { params });
		return response.data;
	} catch (error) {
		return requestErrorHandler(error);
	}
}

export async function uploadFile(file: any) {
	const formData = new FormData();
	formData.append('file', file);
	try {
		//const response = await axios.post("https://upload.seuapoio.com/", formData);
		const response = await axios.post("fileUpload", formData);
		if (response.data) {
			return response.data;
		}
		throw Error("API_DATA_NOT_FOUND");
	} catch (error) {
		return requestErrorHandler(error);
	}
}

export async function crudMetrics(controller: string, options: any, subEntity: string) {
	if (subEntity) {
		subEntity = `/${subEntity}`;
	}
	try {
		const response = await axios.post(`${controller}${subEntity}/getMetrics`, options);
		return response.data;
	} catch (error) {
		return requestErrorHandler(error);
	}
}
